import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Article from "../components/articles/article";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/articles.css";
import axios from "axios";

const Articles = () => {

	const [articles, setArticles] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const data = `query Publication {
			publication(host: "joymendonca.hashnode.dev"){
			  title
			  posts(first: 15){
				edges {
				  node {
					id
					title
					brief
					url
					subtitle
					publishedAt
				  }
				}
			  }
			}
		  }`
		axios({
			url: 'https://gql.hashnode.com',
			method: 'post',
			data: {query: data}
		}).then((result) => {
			if(result.data.data.publication.posts.edges){
				const articleData = result.data.data.publication.posts.edges;
				let articleArr = [];
				for(let i=0; i<articleData.length; i++){
					articleArr.push(articleData[i]['node'])
				}
				setArticles(articleArr);
			}
		});
	}, []);

	const currentSEO = SEO.find((item) => item.page === "articles");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Articles | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="articles" />
				<div className="content-wrapper">
					<div className="articles-logo-container">
						<div className="articles-logo">
							<Logo width={156} />
						</div>
					</div>

					<div className="articles-main-container">
						<div className="title articles-title">
							{INFO.articles.title}
						</div>

						<div className="subtitle articles-subtitle">
							{INFO.articles.description}
						</div>

						<div className="articles-container">
							<div className="articles-wrapper">
								{articles.map((article, index) => (
									<div
										className="articles-article"
										key={(index + 1).toString()}
									>
										<Article
											key={(index + 1).toString()}
											date={new Date(article.publishedAt)}
											title={article.title}
											description={article.brief}
											link={"/article/" + article.id}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Articles;
