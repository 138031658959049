import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<div className="work-details">
								<div className="work-title">Cronix LLC</div>
								<div className="work-subtitle">
									Full-stack Developer
								</div>
							</div>
							<div className="work-duration">February 2023 - Present</div>
						</div>

						<div className="work">
							<div className="work-details">
								<div className="work-title">Rising Webvibe Solutions</div>
								<div className="work-subtitle">
									Full-stack Developer
								</div>
							</div>
							<div className="work-duration">June 2022 - February 2023</div>
						</div>
						<div className="work">
							<div className="work-details">
								<div className="work-title">Ecode Networks</div>
								<div className="work-subtitle">
									Trainee System Engineer
								</div>
							</div>
							<div className="work-duration">February 2021 - August 2022</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
