import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import myArticles from "../data/articles";

import "./styles/readArticle.css";
import axios from "axios";

let ArticleStyle = styled.div``;

const ReadArticle = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const [article, setArticle] = useState();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const data = `query Post {
			post(id: "${slug}"){
				title
				brief
				url
				slug
				subtitle
				publishedAt
				content {
					html
				}
			}
		  }`
		axios({
			url: 'https://gql.hashnode.com',
			method: 'post',
			data: {query: data}
		}).then((result) => {
			if(result.data.data.post){
				setArticle(result.data.data.post);
			}
		});
	}, []);

	// ArticleStyle = styled.div`
	// 	${article().style}
	// `;
	if(article){
	return (
		<React.Fragment>
			<Helmet>
				<title>{`${article.title} | ${INFO.main.title}`}</title>
				<meta name="description" content={article.brief} />
				
			</Helmet>

			<div className="page-content">
				<NavBar />

				<div className="content-wrapper">
					<div className="read-article-logo-container">
						<div className="read-article-logo">
							<Logo width={156} />
						</div>
					</div>

					<div className="read-article-container">
						<div className="read-article-back">
							<img
								src="../back-button.png"
								alt="back"
								className="read-article-back-button"
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="read-article-wrapper">
							<div className="read-article-date-container">
								<div className="read-article-date">
									{new Date(article.publishedAt).toDateString()}
								</div>
							</div>

							<div className="title read-article-title">
								{article.title}
							</div>

							<div className="read-article-body">
								<div dangerouslySetInnerHTML={{__html:article.content.html}} />
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
	}
	return <></>;
};

export default ReadArticle;
